import React from 'react';
import questionData from '../assets/FAQ.json';
import styles from '../css-modules/Body.module.css';

class FAQ extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search: ''
    }

    this.updateSearch = this.updateSearch.bind(this);
    this.handleClear = this.handleClear.bind(this);
  }

  updateSearch(event) {
    this.setState({
      search: event.target.value
    })
  }

  handleClear() {
    this.setState({
      search: ''
    })
  }

  render() {
    const search = this.state.search.toLowerCase();
    const questionList = questionData.filter(data => {
      return data.question.toLowerCase().includes(search) || data.answer.toLowerCase().includes(search);
    })

    return (
      <div className={styles.aboutSection} style={{marginTop: '5rem'}}>
        <h1>Frequently Asked Questions</h1>
        <div className={styles.searchContainer}>
          <label htmlFor="search">Search FAQ:</label>
          <input type="text"
            onChange={this.updateSearch}
            value={this.state.search}
            placeholder="Search"
          />
          <input type="button" value="Clear Search" onClick={this.handleClear}/>
        </div>

        {questionList.map(q => <Question key={q.question} question={q.question} answer={q.answer} search={this.state.search}/>)}
      </div>
    )
  }
}

function getHighlightedText(text, search) {
  let regex = new RegExp(`\(${search}\)`, 'gi');
  let parts = text.split(regex);

  return (
    <>
      {parts.map((part, i) => {
        return (
          <React.Fragment key={i}>
            {part.toLowerCase() === search.toLowerCase() ? <mark>{part}</mark> : <>{part}</>}
          </React.Fragment>
        )
      })}
    </>
  )
}

function Question(props) {
  if (props.search.length === 0)
    return (
      <>
        <h3>{props.question}</h3>
        <p>{props.answer}</p>
      </>
    );

  return (
    <>
      <h3>{getHighlightedText(props.question, props.search)}</h3>
      <p>{getHighlightedText(props.answer, props.search)}</p>
    </>
  )
}

export default FAQ;
