import React from 'react';
import Layout from '../layouts/layout';
import StoreInfo from '../components/store-info';
import FAQ from '../components/faq-section';

export default () => (
  <Layout>
    <StoreInfo />
    <FAQ />
  </Layout>
)
